
import { Getter, Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { IndexPublicationPayload, Publication, PublicationType } from "@/store/modules/publications.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        ActivityHeaderPart: () => import("@/views/seller/parts/activity/activity-header.vue"),
        ContactInfoPart: () => import("@/views/seller/parts/contact/contact-info.vue"),
        ActivityPublicationsPart: () => import("@/views/seller/parts/activity/activity-publications.vue"),
        ActivityDetailPart: () => import("@/views/seller/parts/activity/activity-detail.vue"),
    },
})
export default class PageSellerEstate extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("activity/viewing") activity!: Activity;
    @Getter("publications/all") publications!: Publication[];

    @Action("activity/read") readActivity!: ActivityRead;

    loading = false;

    get websitePublication() {
        return this.publications.find((p) => p.type === PublicationType.website);
    }

    get canShare() {
        return navigator.share !== undefined;
    }

    async mounted() {
        await this.readActivity({ id: Number(this.$route.params.id) });
    }

    async share() {
        if (!this.websitePublication) {
            return;
        }

        const shareData = {
            title: this.activity.name,
            text: this.activity.name,
            url: this.websitePublication.url,
        };

        navigator.share(shareData);
    }
}
